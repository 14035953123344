<template>
    <div>
        <b-form-group v-if="isSuperDebugUser()"
                      label-cols="6"
                      label="Abilita Super Debug"
                      label-for="label"
                      style="max-width: 400px;"
        >
            <b-form-checkbox v-model="enableSuperDebugCheck" v-on:change="onEnableSuperDebugChange" class="mb-2 mt-2 mr-sm-2 mb-sm-0">
            </b-form-checkbox>
        </b-form-group>
        <div class="superdebuguser col-12" v-if="isSuperDebugUser() && enableSuperDebug">
            <div class="row">
                <div class="col-3">
                    <b-form-group
                            label-cols="6"
                            label="Abilita Builder"
                            label-for="label"
                    >
                        <b-form-checkbox v-on:change="onDebugBuilderShowHide()" v-model="debugOptions.showBuilder" class=""> <!-- @change="reload()"-->
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group
                            label-cols="6"
                            label="Visualizza Debug"
                            label-for="label"
                    >
                        <b-form-checkbox v-model="debugOptions.showDebug" class="mb-2 mr-sm-2 mb-sm-0">
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group
                            label-cols="6"
                            label="Invio Finto"
                            label-for="label"
                    >
                        <b-form-checkbox v-model="debugOptions.debugSend" class="mb-2 mr-sm-2 mb-sm-0">
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group
                            label-cols="6"
                            label="Versioni precedenti"
                            label-for="label"
                    >
                        <b-form-checkbox v-model="debugOptions.previousVersions" v-on:change="loadPreviousVersions"
                                         class="mb-2 mr-sm-2 mb-sm-0">
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-12" v-if="debugOptions.previousVersions" style="max-height:200px;overflow-y:auto">
                    <table class="table" v-if="debugData.revisions">
                        <tr v-for="item in debugData.revisions" :key="item.id">
                            <!--                            <td>{{item.id}}</td>-->
                            <!--                            <td>{{item.revision_id}}</td>-->
                            <td>{{item.created_at}}</td>
                            <td>{{item.email}}</td>
                            <td><a style="cursor:pointer" @click="showPreviousVersion(item)">Mostra</a>
                                <div v-if="item.prev">
                                    <table style="width:100%">
                                        <thead>
                                        <th>CONFIG</th>
                                        <th>FORM</th>
                                        <th>SUBMIT</th>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <textarea style="width: 100%">{{item.prev}}</textarea>
                                            </td>
                                            <td>
                                                <textarea style="width: 100%">{{item.prevform}}</textarea>
                                            </td>
                                            <td>
                                                <textarea style="width: 100%">{{item.prevsubmit}}</textarea>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <a v-if="false" style="cursor:pointer" @click="copyPreviousVersion(item.id)">Copia</a></td>
                        </tr>
                    </table>
                </div>
                <div class="col-12" v-show="debugOptions.showBuilder">
                    <!--                    <FormBuilder id="formBuilder" ref="formBuilder" v-bind:form="config.formbuilder" v-if="config.formbuilder" v-bind:options="{}" v-on:change="updateSchema"></FormBuilder>-->
                    <FormBuilder v-on:render="onRender" id="formBuilder" ref="formBuilder" v-bind:form="config.formbuilder"
                                 v-if="config.formbuilder" v-bind:options="{}" v-on:change="updateSchema"></FormBuilder>
                </div>
                <div class="col-12 mt-5" v-if="debugOptions.showDebug">
                    <table class="form-test" style="width: 100%">
                        <thead>
                        <tr>
                            <th>Formio</th>
                            <th>JS Submit</th>
                            <th>Submit result</th>
                            <th>CONFIG</th>
                            <th>JS Init</th>
                            <th>Extra Config</th>
                            <th>Form data</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <textarea style="width: 100%" v-on:change="formDebugChange"
                                          v-model="configDebug"></textarea>
                                <br>
                                <b-button @click="formMoveTabs" class="btn-primary">Sposta tab</b-button>
                                <br>
                                <v-icon v-if="prevNextData['cformform'] && prevNextData['cformform'].hasPrev"
                                      @click="configDebug=prevNext.prev('cformform'); updatePrevNext('cformform'); formDebugChange();">mdi-undo</v-icon>&nbsp;
                                <v-icon v-if="prevNextData['cformform'] && prevNextData['cformform'].hasNext"
                                      @click="configDebug=prevNext.next('cformform'); updatePrevNext('cformform'); formDebugChange();">mdi-redo</v-icon>
                            </td>
                            <td>
                                <textarea style="width: 100%"
                                          v-on:change="formSubmitChange"
                                          v-model="config.formsubmit"></textarea>
                                <br>
                                <b-button @click="formSubmitValidate" class="btn-primary">Valida</b-button>
                                <br>
                                <span v-if="formSubmitValidationResult"
                                      v-bind:class="{ 'bg-success': formSubmitValidationResult==='OK', 'bg-danger': formSubmitValidationResult!=='OK' }"
                                      style="font-style: italic;color:#000;padding:10px;clear:both;margin:5px 0;display: block">{{formSubmitValidationResult}}</span>
                                <br>
                                <v-icon v-if="prevNextData['cformsubmit'] && prevNextData['cformsubmit'].hasPrev"
                                      @click="config.formsubmit=prevNext.prev('cformsubmit'); updatePrevNext('cformsubmit'); formSubmitChange()">mdi-undo</v-icon>&nbsp;
                                <v-icon v-if="prevNextData['cformsubmit'] && prevNextData['cformsubmit'].hasNext"
                                      @click="config.formsubmit=prevNext.next('cformsubmit'); updatePrevNext('cformsubmit'); formSubmitChange()">mdi-redo</v-icon>
                            </td>
                            <td>
                                <textarea style="width: 100%">{{formresult}}</textarea>
                            </td>
                            <td>
                                <textarea v-on:change="updatedFormConfig" style="width: 100%" v-model="formConfig"></textarea><br>
                                <click-confirm>
                                    <b-button v-on:click="saveConfig" class="btn-primary">Salva</b-button>
                                </click-confirm>
                                <br>
                                <v-icon v-if="prevNextData['cformconfig'] && prevNextData['cformconfig'].hasPrev"
                                        @click="formConfig=prevNext.prev('cformconfig'); updatePrevNext('cformconfig'); updatedFormConfig();">mdi-undo</v-icon>&nbsp;
                                <v-icon v-if="prevNextData['cformconfig'] && prevNextData['cformconfig'].hasNext"
                                        @click="formConfig=prevNext.next('cformconfig'); updatePrevNext('cformconfig'); updatedFormConfig();">mdi-redo</v-icon>
                            </td>
                            <td>
                                <textarea v-on:change="formInitChange" style="width: 100%" v-model="config.forminit"></textarea>
                            </td>
                            <td>
                                <textarea v-on:change="extraConfigChange" style="width: 100%" v-model="config.extraconfig"></textarea>
                                <br>
                                <b-button @click="formExtraConfigValidate" class="btn-primary">Valida</b-button>
                                <br>
                                <span v-if="formExtraConfigValidationResult"
                                      v-bind:class="{ 'bg-success': formExtraConfigValidationResult==='OK', 'bg-danger': formExtraConfigValidationResult!=='OK' }"
                                      style="font-style: italic;color:#000;padding:10px;clear:both;margin:5px 0;display: block">{{formExtraConfigValidationResult}}</span>
                                <br>
                            </td>
                            <td>
                                    <textarea style="width: 100%" v-model="submissionDataDebug"
                                              v-on:change="submissionDataDebugChange"></textarea><br>
                                <b-button @click="formDataReset" class="btn-primary">Reset dati</b-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <!--            <textarea>{{config.formsubmit}}</textarea>-->
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {FormBuilder} from 'vue-formio'; // used by builder only
    import Vue from "vue";
    import JwtService from "@/common/jwt.service";
    import ApiService from "../../../../../common/api.service";
    import UtilService from "../../../../../common/util.service";
    import UserService from "../../../../../common/user.service";
    import $ from "jquery";

    export default {
        data() {
            return {
                submissionDataDebug: null,
                debugOptions: {},
                enableSuperDebugCheck: false,
                enableSuperDebug: false,
                debugData: {revisions: null},
                configDebug: null,
                formConfig: null,
                prevNext: UtilService.prevNext,
                prevNextData: {},
                formSubmitValidationResult: '',
                formExtraConfigValidationResult: '',
            }
        },
        name: "PageDocumentFormDebug",
        props: {
            config: Object,
            contractData: Object,
            formresult: Object,
            currentContractId: String,
            submission: Object,

        },
        components: {
            FormBuilder,
        },
        created(){
            this.enableSuperDebug = !!localStorage.getItem('enableSuperDebugForm');
            this.enableSuperDebugCheck = this.enableSuperDebug;
        },
        methods: {
            onDebugBuilderShowHide: function () {
              this.$emit('debugbuildershowhide', {});
              if(this.debugOptions.showBuilder){
                // bad fix to fix dialogs without working buttons
                setTimeout(()=>{
                  window.intervalDebugBuilderShowFixButtons = setInterval(()=>{
                    if($('.component-settings-button-edit-json').length){
                      clearInterval(window.intervalDebugBuilderShowFixButtons);
                      $('.component-settings-button-edit-json').click();
                      $('.component-settings .formio-dialog-close').click();
                    }
                  },100);
                },1);
              }
            },
            onEnableSuperDebugChange: function(){
                const that = this;
                if(!window.confirm('Attenzione, si ricaricherà la pagina!')){
                    setTimeout(() => {
                        that.enableSuperDebugCheck = !that.enableSuperDebugCheck;
                    }, 0);
                    /*Vue.nextTick(() => {
                        that.debugOptions.enableSuperDebug = false;
                    });*/
                }else{
                    if(this.enableSuperDebugCheck){
                        localStorage.setItem('enableSuperDebugForm','1');
                    }else{
                        localStorage.removeItem('enableSuperDebugForm');
                    }
                    window.location.reload();
                }
            },
            saveConfig: function (e) {
                let datasend = {
                    'label': this.contractData.contract.label,
                    'esignature_account_id': this.contractData.contract.esignature_account_id,
                    'form': this.getFormConfig(),
                    'opts': this.contractData.contract.opts,
                };
                ApiService.post('contracts/' + this.currentContractId, datasend)
                    .then(({data}) => {
                        Vue.customNotifySuccess('Salvato');
                    })
                    .catch(({response}) => {
                        Vue.customNotifyError('Errore', response.data && response.data.errors ? response.data.errors.join(', ') : 'Si prega di riprovare');
                    });
            },

            updatedFormConfig: function (e) {
                let val;
                if(e) {
                    val = JSON.parse(e.target.value, true);
                }else{
                    val = JSON.parse(this.formConfig);
                }
                this.config.formbuilder = val['form'];
                this.config.formsubmit = val['formsubmit'];
                this.config.forminit = val['forminit'];
                this.config.extraconfig = val['extraconfig'];
                this.updateSchema();
            },
            getFormConfig: function () {
                return JSON.stringify({
                    'form': this.config.form,
                    'formsubmit': this.config.formsubmit,
                    'forminit': this.config.forminit,
                    'extraconfig': this.config.extraconfig
                }, null, 2).replace(new RegExp(JwtService.getToken(), 'g'), 'MAINAPITOKEN').replace(new RegExp('"value": "Bearer (.*?)"', 'g'), '"value": "Bearer MAINAPITOKEN"');
            },
            isSuperDebugUser: function () {
                return UserService.isSuperDebugUser();
            },
            updateSchema: function (schema) {
                this.config.form = Vue.util.extend({}, this.config.formbuilder);
                Vue.set(this.config,'form',Vue.util.extend({}, this.config.formbuilder));
                this.configDebug = JSON.stringify(this.config.formbuilder, null, 2);
                this.prevNext.store('cformform', this.configDebug);
                this.updatePrevNext('cformform');
                this.formConfig = this.getFormConfig();
                this.prevNext.store('cformconfig', this.formConfig);
                this.updatePrevNext('cformconfig');
                // console.log(schema);
            },
            enableDebugSend: function () {
                return this.debugOptions.debugSend;
            },
            onRender: function (e) {
            },
            formChange: function (e) {
                this.submissionDataDebug = JSON.stringify(e.data);
            },
            formSubmitChange: function(e){
                let val;
                if(e) {
                    val = e.target.value
                }else{
                    val = this.config.formsubmit;
                }
                Vue.set(this.config, 'formsubmit', val);
                this.config.formsubmit = val;
                this.prevNext.store('cformsubmit', this.config.formsubmit);
                this.updatePrevNext('cformsubmit');
                this.formConfig = this.getFormConfig();
                this.prevNext.store('cformconfig', this.formConfig);
                this.updatePrevNext('cformconfig');
            },
            formInitChange: function(e){
                Vue.set(this.config, 'forminit', e.target.value);
                this.config.forminit = e.target.value;
                // this.prevNext.store('cformsubmit', config.formsubmit);
                // updatePrevNext('cformsubmit');
                this.formConfig = this.getFormConfig();
                this.prevNext.store('cformconfig', this.formConfig);
                this.updatePrevNext('cformconfig');
            },
            extraConfigChange: function(e){
                Vue.set(this.config, 'extraconfig', e.target.value);
                this.config.extraconfig = e.target.value;
                this.formConfig = this.getFormConfig();
                this.prevNext.store('cformconfig', this.formConfig);
                this.updatePrevNext('cformconfig');
            },
            showPreviousVersion: function (item) {
                if (this.debugOptions.previousVersions) {
                    ApiService.get('contracts/revision/' + item.id)
                        .then(({data}) => {
                            //item.prev = data.form;
                            Vue.set(item, 'prevform', JSON.parse(data.form)['form']);
                            Vue.set(item, 'prevsubmit', JSON.parse(data.form)['formsubmit']);
                            Vue.set(item, 'prev', data.form);
                        });
                }
            },
            copyPreviousVersion: function (rid) {
                if (this.debugOptions.previousVersions) {
                    ApiService.get('contracts/revision/' + rid)
                        .then(({data}) => {
                            this.$copyText(data.form).then(function (e) {
                                Vue.customNotifySuccess('Copiato');
                            }, function (e) {
                                console.log(e);
                            })
                        });
                }
            },
            checkSubmitSyntax: function () {
                UtilService.parseForESLint('test');
            },
            formDebugChange: function (e) {
                // this.config.form = JSON.parse(e.target.value);
                if(e){
                    this.config.form = JSON.parse(e.target.value);
                    this.config.formbuilder = JSON.parse(e.target.value);
                    this.prevNext.store('cformform', e.target.value);
                }else {
                    this.config.form = JSON.parse(this.configDebug);
                    this.config.formbuilder = JSON.parse(this.configDebug);
                    this.prevNext.store('cformform', this.configDebug);
                }
                this.updatePrevNext('cformform');
                this.formConfig = this.getFormConfig();
                this.prevNext.store('cformconfig', this.formConfig);
                this.updatePrevNext('cformconfig');
                //this.prevNextData = this.prevNext.v;
                // this.configDebug = JSON.stringify(this.config.formbuilder, null, 2);
            },
            formMoveTabs: function (s) {
                let old_index = window.prompt('Che tab vuoi spostare?');
                if(!old_index){
                    return;
                }
                old_index--;
                let new_index = window.prompt('In che posizione?');
                if(!new_index){
                    return;
                }
                new_index--;
                const arr = this.config.formbuilder['components'];


                while (old_index < 0) {
                    old_index += arr.length;
                }
                while (new_index < 0) {
                    new_index += arr.length;
                }
                if (new_index >= arr.length) {
                    var k = new_index - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
                this.config.formbuilder['components'] = arr;
                this.updateSchema();
                this.formDebugChange();

                // this.updateSchema();
            },
            updatePrevNext: function (s) {
                // console.log('updatedPrevNext', this.prevNext.v);
                Vue.set(this.prevNextData, s, this.prevNext.v[s]);
            },
            formSubmitValidate: function (e) {
                const res = UtilService.isValidJs(this.config.formsubmit);
                if (res !== true) {
                    this.formSubmitValidationResult = 'ERRORE ' + "\n" + ' linea ' + res.lineNumber + ' colonna ' + res.column + ' - ' + res.message;
                } else {
                    this.formSubmitValidationResult = 'OK';
                }
            },
            formExtraConfigValidate: function (e) {
                //const res = UtilService.isValidJs2(this.config.extraconfig);
                let res = true;
                try{
                  eval('var c='+this.config.extraconfig+';');
                }catch(e){
                  res = false;
                }
                if (res !== true) {
                    //this.formExtraConfigValidationResult = 'ERRORE ' + "\n" + ' linea ' + res.lineNumber + ' colonna ' + res.column + ' - ' + res.message;
                    this.formExtraConfigValidationResult = 'ERRORE';
                } else {
                    this.formExtraConfigValidationResult = 'OK';
                }
            },
            loadPreviousVersions: function () {
                if (this.debugOptions.previousVersions) {
                    ApiService.get('contracts/revisions/' + this.currentContractId)
                        .then(({data}) => {
                            this.debugData.revisions = data;
                        });
                }
            },
            formDataReset: function (e) {
                this.$emit('submissionchanged', {});
            },
            submissionDataDebugChange: function (e) {
                // console.log(e);
                // this.submission.data = JSON.parse(e.target.value);
                // this.submission = Object.assign({}, JSON.parse(JSON.stringify(this.submission)));
                // Vue.set(this.submission, 'data', JSON.parse(e.target.value));
                this.$emit('submissionchanged', JSON.parse(e.target.value));
            },
            loaded: function () {
                const that = this;
                if(!this.enableSuperDebug){
                    this.$emit('loaded', true);
                    return;
                }
                that.config.formbuilder = that.config.form;
                that.configDebug = JSON.stringify(that.config.formbuilder, null, 2);
                this.formConfig = this.getFormConfig();
                this.prevNext.store('cformsubmit', this.config.formsubmit);
                this.prevNext.store('cformform', this.configDebug);
                this.prevNext.store('cformconfig', this.formConfig);
                setTimeout(()=>{
                    that.$refs.formBuilder.builder.instance.form = that.config.form;
                    setTimeout(()=>{
                        this.$emit('loaded', true);
                    },1);
                },1);
                // setTimeout(() => {
                //     const tempData = that.submissionDataDebug;
                //
                //     setTimeout(()=>{
                //         that.$emit('submissionchanged', tempData);
                //         console.log('tempdata', tempData);
                //     },10000);
                // }, 1);
            },
            debugSendData: function (data) {
                const byteCharacters = atob(data.file);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], {type: 'application/pdf'});
                if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
                    window.navigator.msSaveOrOpenBlob(blob, 'exportData' + new Date().toDateString() + '.pdf');
                } else {
                    var a = window.document.createElement("a");
                    a.href = window.URL.createObjectURL(blob, {type: "application/pdf"});
                    a.download = "exportData" + new Date().toDateString() + ".pdf";
                    document.body.appendChild(a);
                    a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
                    document.body.removeChild(a);
                }
            }
        }
    };
</script>
